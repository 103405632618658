import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { PaymentSearchResults } from '@src/components/payments/PaymentSearchResults';
import { PaymentSearch } from '@src/components/payments/PaymentSearch';
import {
  FormContainer,
  FormOuterContainer,
} from '@src/common/utils/StyleConstant';

import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import { SubHeader } from '@src/common/components';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { HelpContent, HelpContentResult } from '@src/components/payments/utils';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { MlreLoader } from '../../common/MlreAbyssComponent';

const Payment = () => {
  const { navigate, getLocation } = useRouter();
  const { hash, state } = getLocation();
  const [dataLoading, setDataLoading] = useState(false);
  const [showPaymentSearchResult, setsPaymentSearchResult] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [breadItem, setBreadItem] = useState([
    { title: 'Payment Search', href: '/Payment' },
  ]);

  const isTable = hash == '#table';
  const breadcrumData = state?.breadcrumData;

  useEffect(() => {
    if (!isTable) {
      setsPaymentSearchResult(false);
    } else if (breadcrumData) {
      setBreadItem([
        { title: 'Payment Search', href: '/Payment' },
        { title: 'Payment Search Result', href: '/Payment#table' },
      ]);
      setsPaymentSearchResult(true);
      setPaymentData(breadcrumData);
    } else {
      setsPaymentSearchResult(true);
      // navigate('');
    }
  }, [isTable]);

  const onSearch = async (data, show) => {
    setDataLoading(true);

    try {
      await new Promise((resolve) => {
        return setTimeout(resolve, 2000);
      }); // 2-second

      navigate('#table');
      setPaymentData(data);
      setsPaymentSearchResult(show);
      setBreadItem([
        { title: 'Payment Search', href: '/Payment' },
        { title: 'Payment Search Result', href: '/Payment#table' },
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setDataLoading(false);
    }
  };

  const isMedium = useMediaQuery('(max-width: 744px)');

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>

          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              {dataLoading ? (
                <MlreLoader />
              ) : (
                <React.Fragment>
                  <SubHeader
                    breadItem={breadItem}
                    infoTitle={isTable ? 'Payment Results' : 'Payment Search'}
                    InfoContent={isTable ? HelpContentResult : HelpContent}
                  />
                  {!showPaymentSearchResult ? (
                    <FormOuterContainer>
                      <FormContainer medium={isMedium}>
                        <PaymentSearch
                          onPaymentSearch={onSearch}
                        />
                      </FormContainer>
                    </FormOuterContainer>
                  ) : (
                    <PaymentSearchResults paymentData={paymentData} />
                  )}
                </React.Fragment>
              )}
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default withAuth(Payment);
