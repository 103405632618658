/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Router } from '@abyss/web/ui/Router';
import { Button } from '@abyss/web/ui/Button';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Link } from '@abyss/web/ui/Link';
import { useRouter } from '@abyss/web/hooks/useRouter';
import axios from 'axios';
import useAuth from '@src/auth/useAuth';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { SearchInput } from '@abyss/web/ui/SearchInput';
import { BASE_URL } from '../utils/endpoints';
import {
  MlreDataTable,
  DataTableSearchLabel,
  MlreLoader,
} from '../../common/MlreAbyssComponent';
import env from '../../../env.json';

export const AggregationOprationDataTable = ({ searchData, breadItem }) => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableCount, setTableCount] = useState(100);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  const [selectedAso, setSelectedAso] = useState(null);
  const [selectedAsoRow, setSelectedAsoRow] = useState([]);
  const [redirectData, setRedirectData] = useState();
  const { navigate } = useRouter();
  const { token, userRolesGroups, msid } = useAuth();
  const [searchValue, setSearchValue] = useState();

  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
  const [isCustomerSearch, setIsCustomerSearch] = useState(false);

  useEffect(() => {
    const hasUpdateAccess = env.AOG_ASO_Exception.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!hasUpdateAccess) {
      setIsCheckboxDisabled(true);
    } else {
      setIsCheckboxDisabled(false);
    }
  }, [userRolesGroups]);

  const fetchData = async (
    page,
    pageSize,
    sortBy,
    globalFilter,
    columnFilters
  ) => {
    const payload = {
      ...searchData,
      pageSize,
      pageNum: page,
      sortBy,
    };
    if (globalFilter) {
      payload.Policy = globalFilter;
      payload.isFilter = true;
    }
    try {
      const { data, error } = await axios.post(
        `${BASE_URL}aog/search`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return {
        results: globalFilter ? data.data : data,
        count: globalFilter ? data.count : tableCount,
        reloadData: true,
      };
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  const fetchCount = async () => {
    try {
      const { data, error } = await axios.post(
        `${BASE_URL}aog/count`,
        searchData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!error && data.length > 0) {
        setTableCount(data[0].count);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  useEffect(() => {
    if (searchData) {
      if (searchData.dataCount) {
        // setPageIndex(searchData.pageIndex);
        setTableCount(searchData.dataCount);
        setIsLoading(false);
      } else {
        fetchCount();
      }
    }
  }, [searchData]);

  useEffect(() => {
    if (redirectData) {
      const { navigateData } = redirectData;

      const payload = {
        rbtReportYr: searchData.rbtRptYr,
        srcSystemCd: navigateData?.srcSysCd,
        srcSysCustId: navigateData?.srcSysCustContrId,
        atr5: searchData?.runTypCd == 'M' ? 'STT' : 'FED',
      };
      const breadcrums = breadItem;
      const lastBCItem = breadcrums[breadcrums.length - 1];
      lastBCItem.state = { ...lastBCItem.state, dataCount: tableCount };

      navigate('/aog-customer', { state: { payload, breadcrums } });
    }
  }, [redirectData]);

  const handleAso = async () => {
    if (selectedAsoRow.length == 0) {
      alert('Please select a record to save');
      return false;
    }
    const payload = [];
    selectedAsoRow.map((row) => {
      payload.push({
        srcSysCd: row?.srcSysCd,
        asoXcptInd: row?.asoXcptInd,
        rbtRptYr: row?.rbtReportYr,
        asoXcptIndModById: msid,
        runTypCd: searchData?.runTypCd,
        srcSysCustContrId: row?.srcSysCustContrId,
        runTypSeqNbr: row?.runTypSeqNbr,
        modifiedDate: row?.modifiedDate,
      });
    });

    try {
      const { data, error } = await axios.post(
        `${BASE_URL}aog/updateaso`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!error) {
        setSelectedAsoRow([]);
        alert('Update successful');
        setReloadData(true);
        setTimeout(() => {
          setReloadData(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  useEffect(() => {
    if (selectedAso) {
      const selectedRow = selectedAsoRow.filter((row) => {
        return row?.aggrOperGrpId != selectedAso?.aggrOperGrpId;
      });
      selectedRow.push(selectedAso);
      setSelectedAsoRow(selectedRow);
    }
  }, [selectedAso]);

  const aogHeader = () => {
    return [
      {
        Header: 'ASO Exception',
        accessor: 'asoXcptInd',
        disableSortBy: true,
        Cell: ({ value, cellActions, row, ...props }) => {
          const colSys = row.original.srcSysCd
            ? row.original.srcSysCd.substring(
                0,
                row.original.srcSysCd.indexOf('-')
              )
            : '';

          const isDisabled =
            isCheckboxDisabled ||
            (row.original.srcSysCd != 'UNET' && colSys != 'UNET');

          return (
            <label htmlFor={row.id}>
              <input
                id={row.id}
                type="checkbox"
                disabled={isDisabled}
                onChange={() => {
                  cellActions.modifyRow(row, {
                    asoXcptInd: !value,
                  });
                  if (value) {
                    setSelectedAso({ ...row.original, asoXcptInd: null });
                  } else {
                    setSelectedAso({ ...row.original, asoXcptInd: 'Y' });
                  }
                }}
                style={{ cursor: 'pointer' }}
              />
              <IconSymbol
                color={isDisabled ? '$interactiveCb' : '$interactive1'}
                icon={value ? 'check_box' : 'check_box_outline_blank'}
                size="md"
                style={{ cursor: isDisabled ? 'no-drop' : 'pointer' }}
              />
            </label>
          );
        },
      },
      {
        Header: 'Policy',
        accessor: 'srcSysCustContrId',
        Cell: ({ value, cellActions, row, state, ...props }) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                const navigateData = {
                  ...row.original,
                  id: row.values?.custContrSbscrId,
                  year: searchData.rebateYear,
                };
                setRedirectData({
                  navigateData,
                  pageInd: state.pageIndex,
                });
              }}
              href="#"
            >
              {value}
            </Link>
          );
        },
      },
      {
        Header: 'Primary AOG?',
        accessor: 'priAggrOperGrpInd',
        disableSortBy: true,
      },
      {
        Header: 'Rebatable?',
        accessor: 'inclInRbtInd',
      },
      {
        Header: 'G/L Business Unit',
        accessor: 'glBuId',
      },
      {
        Header: 'G/L Location',
        accessor: 'glLocCd',
      },
      {
        Header: 'G/L Product',
        accessor: 'glPrdctCd',
      },
      {
        Header: 'G/L Customer',
        accessor: 'glCustId',
      },
      {
        Header: 'G/L Operational Unit',
        accessor: 'glOperUnitId',
      },
      {
        Header: 'Source System',
        accessor: 'srcSysCd',
      },
      {
        Header: 'Earned Premium Amount',
        accessor: 'epAmt',
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'Adj. Earned Premium Amount',
        accessor: 'adjEpAmt',
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'Comb Earned Premium Amount',
        accessor: 'custContrCombAogEpAmt',
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'Comb Adj Earned Premium Amount',
        accessor: 'custContrCombAogAdjEpAmt',
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'Calc. Ag. Cust. Contr. Rebate Amount',
        accessor: 'calcAgCustContrRbtAmt',
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
    ];
  };

  const columns = React.useMemo(aogHeader, [isCheckboxDisabled]);

  const searchLabel = [
    { fieldName: 'Rebate Reporting Year', value: searchData.rbtRptYr },
    { fieldName: 'Run Type', value: searchData.runTypCd },
    { fieldName: 'Run Sequence', value: searchData.runTypSeqNbr },
    { fieldName: 'Source System', value: searchData.srcSysCd || 'All' },
    { fieldName: 'MLR Case Size', value: searchData.mlrCseSz || 'All' },
    { fieldName: 'Situs State', value: searchData.mlrSitusCd || 'All' },
  ];

  if (isLoading) return <MlreLoader />;
  const config = {
    pageSizeDefault: 10,
    uniqueStorageId: `server-side-aog`,
    showGlobalFilter: true,
    apiPaginationCall: fetchData,
    manualGlobalFilter: true,
    manualSortBy: true,
    showColumnSort: true,
    apiQueryOptions: {
      requestPolicy: 'no-cache',
    },
  };

  return (
    <React.Fragment>
      <Router.MetaTags title="Aggregation Group Table" />
      <Grid>
        <Grid.Col span={12}>
          <DataTableSearchLabel searchLabel={searchLabel} />

          <MlreDataTable
            columns={columns}
            config={config}
            reloadData={reloadData}
            refreshColumn
          />

          <Grid css={{ justifyContent: 'center' }}>
            <Button
              style={{ marginTop: '15px', marginRight: '5px' }}
              onClick={handleAso}
              isDisabled={isCheckboxDisabled}
            >
              Save
            </Button>
            <Button
              style={{ marginTop: '15px', marginLeft: '5px' }}
              isDisabled={isCheckboxDisabled}
              onClick={() => {
                setReloadData(true);
                setSelectedAsoRow([]);
                setTimeout(() => {
                  setReloadData(false);
                }, 1000);
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
