// withAuth.js
import React, { useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

import { useRouter } from '@abyss/web/hooks/useRouter';

const withAuth = (WrappedComponent) => {

  const AuthenticatedComponent = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { navigate } = useRouter();

    if (!isAuthenticated) {
      navigate('/');
    }

    return isAuthenticated ? <WrappedComponent /> : null;
  };

  return AuthenticatedComponent;
};

export default withAuth;