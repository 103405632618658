import React, { useState, useEffect } from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { Grid } from '@abyss/web/ui/Grid';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import axios from 'axios';
import useAuth from '@src/auth/useAuth';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { isEqual, isNaN } from 'lodash';
import notes_available from '../../assets/notes_available.png';
import notes_empty from '../../assets/notes_empty.png';
import { CustomerNote } from './CustomerNote';
import { CustomerPayment } from './CustomerPayment';
import { CustomerBroker } from './CustomerBroker';
import { CustomerHoldStatus } from './CustomerHoldStatus';
import { BASE_URL } from '../utils/endpoints';
import env from '../../../env.json';
import { CustomerHistory } from './CustomerHistory';
import { CustomerContractNote } from './CustomerContractNote';

export const CustomerPolicyForm = ({ formData, breadcrumsInfo }) => {
  const { getLocation } = useRouter();
  const { state } = getLocation();
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [isUpdateFieldEditable, setIsUpdateFieldEditable] = useState(true);
  const [isHCRWA, setIsHCRWA] = useState(true);
  const [isHCRCategory, setIsHCRCategory] = useState(true);
  const [hcrAtp, setHcrAtp] = useState([]);
  const [hcrWA, setHcrWA] = useState([]);
  const [hcrCategory, setHcrCategory] = useState([]);
  const [hcrSoloProp, setHcrSoloProp] = useState([]);
  const [currentYear, setCurrentYear] = useState([]);
  const [notes, setNotes] = useState([]);
  const [mlreState, setMlreState] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isNoteButtonDisabled, setIsNoteButtonDisabled] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [componentType, setComponentType] = useState('');
  const { token, userRolesGroups } = useAuth();
  const [isPolicyNameEditable, setIsPolicyNameEditable] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isSaveButtonDisabled, setSaveIsButtonDisabled] = useState(false);
  const [notesStatus, setNotesStatus] = useState('empty');
  const [refreshNote, setRefreshNote] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const custType = formData[0]?.custTypeCd;
  const rbtReportYr = formData[0]?.rbtReportYr;

  useEffect(() => {
    if (custType === 'I') {
      setIsPolicyNameEditable(true);
    }
  }, [custType]);

  const changeComponent = (type) => {
    setComponentType(type);
    window.scrollTo(0, 280);
  };

  const fetchCurrentYear = async () => {
    try {
      const response = await axios.get(`${BASE_URL}customer/year`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });
      setCurrentYear(response.data[0]?.currentYear);
      console.log('current year', response.data[0]?.currentYear);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMlreState = async () => {
    try {
      const response = await axios.get(`${BASE_URL}customer/getmlreState`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });
      setMlreState(response.data[0]?.count);
      console.log('mlre state', response.data[0]?.count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showHistory) {
      changeComponent('history');
    }
  }, [showHistory]);

  const zip = formData[0]?.custContrZipCd || '';
  const zip2 = formData[0]?.custContrExZipCd || '';

  let splitZip = zip?.includes('-') ? zip.split('-') : [zip, zip2];

  if (!zip.includes('-') && zip2) {
    splitZip = [zip, zip2];
  }

  const formDefaultData = {
    ...formData,
    'zip-code': splitZip?.[0],
    'zip-code2': splitZip?.[1],
    'hcr-erita-catg':
      formData[0]?.custContrCatgyCd || (custType === 'I' ? '5' : ''),
    'hcr-wa': formData[0]?.wrtAsurcInd || (custType === 'I' ? 'N' : ''),
  };

  const form = useForm({
    defaultValues: {
      'acct-mgr-first-name': formData[0]?.accMgrFstNm,
      'acct-mgr-last-name': formData[0]?.accMgrLstNm,
      'acct-mgr-email': formData[0]?.accMgrEmail,
      'cust-type': formData[0]?.custTypeCd,
      'policy-name': formData[0]?.custContrNm,
      'hcr-atne': formData[0]?.hcrAtne,
      'hcr-atp': formData[0]?.hcrAtp,
      'hcr-sole-prop': formData[0]?.hcrSolePropr,
      'contact-first-name': formData[0]?.custCntcFstNm,
      'contact-last-name': formData[0]?.custCntcLstNm,
      'contact-email': formData[0]?.custCntcEmailTxt,
      'cntct-phne': formData[0]?.custCntcTelNbr,
      'hcr-wa': formDefaultData['hcr-wa'],
      'hcr-erita-catg': formDefaultData['hcr-erita-catg'],
      'mlre-sole-prop': formData[0]?.mlreSolPropr,
      address: formData[0]?.custContrAdrLn1,
      address1: formData[0]?.custContrAdrLn2,
      address2: formData[0]?.custContrAdrLn3,
      city: formData[0]?.custContrCityNm,
      state: formData[0]?.custContrStCd,
      'zip-code': formDefaultData['zip-code'],
      'zip-code2': formDefaultData['zip-code2'],
    },
  });

  useEffect(() => {
    const zip = formData[0]?.custContrZipCd || '';
    const zip2 = formData[0]?.custContrExZipCd || '';

    let splitZip = zip?.includes('-') ? zip.split('-') : [zip, zip2];

    if (!zip.includes('-') && zip2) {
      splitZip = [zip, zip2];
    }

    const formDefaultData = {
      ...formData,
      'zip-code': splitZip?.[0],
      'zip-code2': splitZip?.[1],
      'hcr-erita-catg':
        formData[0]?.custContrCatgyCd || (custType === 'I' ? '5' : ''),
      'hcr-wa': formData[0]?.wrtAsurcInd || (custType === 'I' ? 'N' : ''),
    };

    setInitialValues({
      'acct-mgr-first-name': formData[0]?.accMgrFstNm || '',
      'acct-mgr-last-name': formData[0]?.accMgrLstNm || '',
      'acct-mgr-email': formData[0]?.accMgrEmail || '',
      'cust-type': formData[0]?.custTypeCd || '',
      'policy-name': formData[0]?.custContrNm || '',
      'hcr-atne': formData[0]?.hcrAtne || '',
      'hcr-atp': formData[0]?.hcrAtp || '',
      'hcr-sole-prop': formData[0]?.hcrSolePropr || '',
      'contact-first-name': formData[0]?.custCntcFstNm || '',
      'contact-last-name': formData[0]?.custCntcLstNm || '',
      'contact-email': formData[0]?.custCntcEmailTxt || '',
      'cntct-phne': formData[0]?.custCntcTelNbr || '',
      'hcr-wa': formDefaultData['hcr-wa'],
      'hcr-erita-catg': formDefaultData['hcr-erita-catg'],
      'mlre-sole-prop': formData[0]?.mlreSolPropr || '',
      address: formData[0]?.custContrAdrLn1 || '',
      address1: formData[0]?.custContrAdrLn2 || '',
      address2: formData[0]?.custContrAdrLn3 || '',
      city: formData[0]?.custContrCityNm || '',
      state: formData[0]?.custContrStCd || '',
      'zip-code': formDefaultData['zip-code'],
      'zip-code2': formDefaultData['zip-code2'],
    });
  }, [formData]);

  const validateFields = (data) => {
    const errors = {};
    if (custType !== 'I') {
      if (!data.address) {
        errors.address = 'Address is required';
      }
      if (!data.city) {
        errors.city = 'City is required';
      }
      if (!data.state) {
        errors.state = 'State is required';
      }
      if (!data['zip-code']) {
        errors['zip-code'] = 'zip is required';
      }
    }
    return errors;
  };

  const handleInputChange = (field, value) => {
    form.setValue(field, value);
    if (formErrors[field]) {
      const updatedErrors = { ...formErrors };
      if (value.trim() !== '') {
        delete updatedErrors[field];
      }
      setFormErrors(updatedErrors);
    }
  };

  const handleDeleteValue = (field) => {
    const data = form.getValues();
    let isFieldDeleted = false;
    if (!data[field] && initialValues[field]) {
      const confirmDelete = window.confirm(
        'Are you sure you want to delete this data?'
      );
      if (!confirmDelete) {
        form.setValue(field, initialValues[field]);
        isFieldDeleted = true;
      }
    }
    return isFieldDeleted;
  };

  const updatePolicy = async () => {
    const data = form.getValues();
    setSaveIsButtonDisabled(true);

    if (custType !== 'I') {
      if (!data.address) {
        alert('Policy Address is required.');
        return;
      }
      if (!data.city) {
        alert('City name is required.');
        return;
      }
      if (!data.state) {
        alert('State code is required.');
        return;
      }
      if (!data['zip-code']) {
        alert('ZIP code is required.');
        return;
      }
    }

    const hcrAtneValue = data['hcr-atne'];
    if (hcrAtneValue !== '') {
      const parseValue = parseInt(hcrAtneValue, 10);
      const isPositiveInteger = /^\d+$/.test(hcrAtneValue) && parseValue >= 0;
      if (!isPositiveInteger) {
        alert('HCR ATNE must be a positive integer.');
        return;
      }
    }

    const isChanged = Object.keys(initialValues).some((key) => {
      return initialValues[key] !== data[key];
    });

    if (!isChanged) {
      alert('No changes to save.');
      setSaveIsButtonDisabled(false);
      return;
    }

    const payload = {
      custContrNm:
        encodeURI(data['policy-name']).replaceAll('%', 'perct') || '',
      rbtReportYr: formData[0]?.rbtReportYr,
      custCntcLstNm: data['contact-last-name'] || '',
      custCntcFstNm: data['contact-first-name'] || '',
      custCntcEmailTxt: data['contact-email'] || '',
      custCntcTelNbr: data['cntct-phne'] || '',
      HcrAtne: hcrAtneValue || '',
      HcrAtp: data['hcr-atp'] || '',
      HcrSolePropr: data['hcr-sole-prop'] || '',
      CustContrCatgyCd: data['hcr-erita-catg'] || '',
      WrtAsurcInd: data['hcr-wa'] || '',
      custContrAdrLn1: encodeURI(data.address).replaceAll('%', 'perct') || '',
      custContrAdrLn2: encodeURI(data.address1).replaceAll('%', 'perct') || '',
      custContrAdrLn3: encodeURI(data.address2).replaceAll('%', 'perct') || '',
      custContrCityNm: data.city || '',
      custContrStCd: data.state || '',
      custContrZipCd: data['zip-code'] || '',
      custContrExZipCd: data['zip-code2'] || '',
      ModDttm: new Date().toISOString(),
      ModById: formData?.[0]?.modById,
      custContrId: formData[0]?.custContrId,
      modifiedDate: formData[0]?.modifiedDate
    };

    try {
      const response = await axios.post(`${BASE_URL}customer/update`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data) {
        alert(response.data);
        setIsUpdateDisabled(true);
        setSaveIsButtonDisabled(false);
        for (const key in data) {
          initialValues[key] = data[key];
        }

        if (componentType === 'history') {
          setComponentType('');
        }
      } else {
        console.log('not updated');
      }
    } catch (error) {
      console.error('Error:', error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
      setSaveIsButtonDisabled(false);
    }
  };

  const handleDropDownChange = (field, value) => {
    const initialValue = initialValues[field];

    if (value === null) {
      const confirmDelete = window.confirm(
        'Are you sure you want to delete this data?'
      );
      if (!confirmDelete) {
        form.setValue(field, initialValue);
      } else {
        form.setValue(field, value);
      }
    } else {
      form.setValue(field, value);
    }
  };

  const onSubmit = (data) => {
    // formData(data);
    console.log('Updated Data:', data);
  };

  const fetchHcrData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}customer/get/hrc/${rbtReportYr}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data) {
        setHcrAtp(response.data);
      } else {
        console.log('data not found');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHcrWAData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}customer/get/HcrWa/${rbtReportYr}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setHcrWA(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHcrCategoryData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}customer/get/HcrCategory/${rbtReportYr}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setHcrCategory(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHcrSoloPropData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}customer/get/HcrSoloProp/${rbtReportYr}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setHcrSoloProp(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisableFields = () => {
    setIsUpdateDisabled(false);
    if (componentType === 'history') {
      setComponentType('');
    }
    if (custType === 'I') {
      setIsPolicyNameEditable(true);
      setIsUpdateFieldEditable(false);
    } else {
      setIsPolicyNameEditable(true);
      setIsUpdateFieldEditable(true);
    }

    if (rbtReportYr === currentYear) {
      if (['E', 'A', 'T', 'P', 'U'].includes(custType)) {
        setIsHCRWA(false);
        setIsHCRCategory(false);
      } else {
        setIsHCRWA(true);
        setIsHCRCategory(true);
      }

      if (mlreState > 0) {
        setIsHCRWA(true);
        setIsHCRCategory(true);
      }
    }
  };

  const fetchNotes = () => {
    const noteType = 'Customer_Contract';
    try {
      const result = axios
        .get(
          `${BASE_URL}customer/get/notes/list/${rbtReportYr}/${formData[0]?.custContrId}/${noteType}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setNotes(response.data);
            setNotesStatus('available');
          } else {
            setNotes([]);
            setNotesStatus('empty');
          }
          console.log('notes data in customer page', response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (componentType === 'note') {
      fetchNotes();
    }
  }, [componentType]);

  useEffect(() => {
    if (refreshNote) {
      fetchNotes();
      setRefreshNote(false);
    }
  }, [refreshNote]);

  useEffect(() => {
    fetchHcrData();
    fetchHcrWAData();
    fetchHcrCategoryData();
    fetchHcrSoloPropData();
    fetchCurrentYear();
    fetchMlreState();
    fetchNotes();
  }, []);

  useEffect(() => {
    const hasUpdateAccess = env.Customer_Update_policy.some((role) => {
      return userRolesGroups.includes(role);
    });

    const hasUpdateNoteAccess = env.Customer_Notes.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }

    if (!hasUpdateNoteAccess) {
      setIsNoteButtonDisabled(true);
    } else {
      setIsNoteButtonDisabled(false);
    }
  }, [userRolesGroups, env.Customer_Update_policy, env.Customer_Notes]);

  const handleReset = () => {
    const currentFormValues = form.getValues();
    const hasUnsavedChanges = !isEqual(currentFormValues, initialValues);

    if (hasUnsavedChanges) {
      Object.keys(initialValues).forEach((key) => {
        form.setValue(key, initialValues[key]);
      });
    }
  };

  const handleCancel = () => {
    const currentFormValues = form.getValues();
    const hasUnsavedChanges = !isEqual(currentFormValues, initialValues);

    if (hasUnsavedChanges) {
      const confirmation = window.confirm(
        'Are you sure you want to cancel without saving changes?'
      );

      if (confirmation) {
        Object.keys(initialValues).forEach((key) => {
          form.setValue(key, initialValues[key]);
        });

        setIsUpdateDisabled(true);
      }
    } else {
      setIsUpdateDisabled(true);
    }
  };

  return (
    <React.Fragment>
      <FormProvider state={form} onSubmit={onSubmit}>
        <Grid
          css={{
            paddingLeft: '12px',
            paddingTop: '40px',
          }}
        >
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              Acct Mgr First Name:
            </Text>
          </Grid.Col>
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text fontWeight="bold" color="#000">
              {formData[0]?.accMgrFstNm}
            </Text>
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              Cust Type:
            </Text>
          </Grid.Col>
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text fontWeight="bold" color="#000">
              {formData[0]?.custTypeCd}
            </Text>
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              Policy Name:
            </Text>
          </Grid.Col>

          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <TextInput
              label=""
              descriptorsDisplay="column"
              model="policy-name"
              isDisabled={isUpdateDisabled ? true : !isPolicyNameEditable}
              onBlur={(e) => {
                if (e.target.value) {
                  form.setValue('policy-name', e.target.value.toUpperCase());
                } else {
                  handleDeleteValue('policy-name');
                }
              }}
            />
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              Acct Mgr Last Name:
            </Text>
          </Grid.Col>
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text fontWeight="bold" color="#000">
              {formData[0]?.accMgrLstNm}
            </Text>
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              HCR ATNE:
            </Text>
          </Grid.Col>
          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <TextInput
              label=""
              descriptorsDisplay="column"
              model="hcr-atne"
              maxLength={10}
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              onBlur={(e) => {
                if (e.target.value) {
                  form.setValue('hcr-atne', e.target.value.toUpperCase());
                } else {
                  handleDeleteValue('hcr-atne');
                }
              }}
            />
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Grid>
              <Grid.Col span={{ xl: 6 }}>
                <Text color="#000" fontWeight="bold">
                  Address:
                  <Text
                    color="#000"
                    fontWeight="bold"
                    css={{ color: 'red !important' }}
                  >
                    *{' '}
                  </Text>
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xl: 6 }}>
                <Tooltip content="History">
                  <Button
                    rounded
                    size="$sm"
                    css={{ backgroundColor: 'none !important' }}
                  >
                    <IconMaterial
                      css={{ cursor: 'pointer' }}
                      icon="history"
                      onClick={() => {
                        changeComponent('history');
                      }}
                    />
                  </Button>
                </Tooltip>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <TextInput
              label=""
              descriptorsDisplay="column"
              model="address"
              // validators={custType !== 'I' ? { required: true } : null}
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              css={{ margintTop: '-10px' }}
              onChange={(e) => {
                return handleInputChange('address', e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value) {
                  form.setValue('address', e.target.value.toUpperCase());
                }
              }}
            />
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              Acct Mgr Email ID:
            </Text>
          </Grid.Col>
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <div
              style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <input
                type="text"
                id="acct-mgr-email"
                value={formData[0]?.accMgrEmail}
                readOnly
                style={{
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '16px',
                  color: '#000',
                  border: 'none',
                  background: 'none',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              />
            </div>
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              HCR ATP:
            </Text>
          </Grid.Col>
          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <SelectInput
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              label=""
              model="hcr-atp"
              options={hcrAtp.map((item: any) => {
                return {
                  value: item.value,
                  label: item.key,
                };
              })}
              value={form.getValues()['hcr-atp']}
              onChange={(value) => {
                return handleDropDownChange('hcr-atp', value);
              }}
            />
          </Grid.Col>
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold" />
          </Grid.Col>

          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <TextInput
              label=""
              hideLabel
              descriptorsDisplay="column"
              model="address1"
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              onBlur={(e) => {
                if (e.target.value) {
                  form.setValue('address1', e.target.value.toUpperCase());
                }
              }}
            />
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              Contact First Name:
            </Text>
          </Grid.Col>

          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <TextInput
              label=""
              descriptorsDisplay="column"
              model="contact-first-name"
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              onBlur={(e) => {
                if (e.target.value) {
                  form.setValue(
                    'contact-first-name',
                    e.target.value.toUpperCase()
                  );
                } else {
                  handleDeleteValue('contact-first-name');
                }
              }}
            />
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              HCR Sole Prop:
            </Text>
          </Grid.Col>
          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <SelectInput
              label=""
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              options={hcrSoloProp.map((item: any) => {
                return {
                  value: item.value,
                  label: item.key,
                };
              })}
              model="hcr-sole-prop"
              value={form.getValues()['hcr-sole-prop']}
              onChange={(value) => {
                return handleDropDownChange('hcr-sole-prop', value);
              }}
            />
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold" />
          </Grid.Col>

          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <TextInput
              label=""
              hideLabel
              descriptorsDisplay="column"
              model="address2"
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              onBlur={(e) => {
                if (e.target.value) {
                  form.setValue('address2', e.target.value.toUpperCase());
                }
              }}
            />
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              Contact Last Name:
            </Text>
          </Grid.Col>

          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <TextInput
              label=""
              model="contact-last-name"
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              onBlur={(e) => {
                if (e.target.value) {
                  form.setValue(
                    'contact-last-name',
                    e.target.value.toUpperCase()
                  );
                } else {
                  handleDeleteValue('contact-last-name');
                }
              }}
            />
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              HCR ERISA Catg:
            </Text>
          </Grid.Col>

          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <SelectInput
              label=""
              model="hcr-erita-catg"
              isDisabled={isUpdateDisabled || isHCRCategory}
              options={
                custType === 'I'
                  ? [{ value: '5', label: 'CHRCH' }]
                  : hcrCategory.map((item: any) => {
                      return {
                        value: item.value,
                        label: item.key,
                      };
                    })
              }
              value={form.getValues()['hcr-erita-catg']}
              onChange={(value) => {
                return handleDropDownChange('hcr-erita-catg', value);
              }}
            />
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              City:
              <Text
                color="#000"
                fontWeight="bold"
                css={{ color: 'red !important' }}
              >
                *
              </Text>
            </Text>
          </Grid.Col>
          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <TextInput
              label=""
              model="city"
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              onChange={(e) => {
                return handleInputChange('city', e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value) {
                  form.setValue('city', e.target.value.toUpperCase());
                }
              }}
            />
          </Grid.Col>
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              Contact Email ID:
            </Text>
          </Grid.Col>

          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <TextInput
              label=""
              model="contact-email"
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              onBlur={(e) => {
                if (e.target.value) {
                  form.setValue('contact-email', e.target.value.toUpperCase());
                } else {
                  handleDeleteValue('contact-email');
                }
              }}
            />
          </Grid.Col>
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              HCR WA:
            </Text>
          </Grid.Col>
          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <SelectInput
              label=""
              model="hcr-wa"
              isDisabled={isUpdateDisabled || isHCRWA}
              options={
                custType === 'I'
                  ? [{ value: 'N', label: 'N' }]
                  : hcrWA.map((item: any) => {
                      return {
                        value: item.value,
                        label: item.key,
                      };
                    })
              }
              value={form.getValues()['hcr-wa']}
              onChange={(value) => {
                return handleDropDownChange('hcr-wa', value);
              }}

              // isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              // options={hcrWA.map((item: any) => {
              //   return {
              //     value: item.value,
              //     label: item.key,
              //   };
              // })}
            />
          </Grid.Col>
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              State:
              <Text
                color="#000"
                fontWeight="bold"
                css={{ color: 'red !important' }}
              >
                *
              </Text>
            </Text>
          </Grid.Col>
          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <TextInput
              label=""
              model="state"
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              onChange={(e) => {
                return handleInputChange('state', e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value) {
                  form.setValue('state', e.target.value.toUpperCase());
                }
              }}
            />
          </Grid.Col>
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              Contact Phone:
            </Text>
          </Grid.Col>
          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 2 }}>
            <TextInput
              label=""
              model="cntct-phne"
              maxLength={40}
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              onBlur={(e) => {
                if (e.target.value) {
                  form.setValue('cntct-phne', e.target.value.toUpperCase());
                } else {
                  handleDeleteValue('cntct-phne');
                }
              }}
            />
          </Grid.Col>
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              MLRE Sole Prop:
            </Text>
          </Grid.Col>
          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text fontWeight="bold" color="#000">
              {formData[0]?.mlreSolPropr}
            </Text>
          </Grid.Col>

          <Grid.Col
            css={{ paddingBottom: '0px' }}
            span={{ xs: 8, md: 3, lg: 4, xl: 2 }}
          >
            <Text color="#000" fontWeight="bold">
              Zip Code:
              <Text
                color="#000"
                fontWeight="bold"
                css={{ color: 'red !important' }}
              >
                *
              </Text>
            </Text>
          </Grid.Col>

          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 1 }}>
            <TextInput
              label=""
              model="zip-code"
              maxLength={5}
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
              onChange={(e) => {
                return handleInputChange('zip-code', e.target.value);
              }}
            />
          </Grid.Col>

          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 1 }}>
            <TextInput
              label=""
              model="zip-code2"
              maxLength={4}
              isDisabled={isUpdateDisabled || !isUpdateFieldEditable}
            />
          </Grid.Col>
        </Grid>
      </FormProvider>

      <Grid>
        <Grid.Col span={12} css={{ textAlign: 'center', marginTop: '20px' }}>
          {isUpdateDisabled ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleDisableFields();
              }}
              isDisabled={isButtonDisabled}
              style={{ margin: '10px', borderRadius: '4px' }}
            >
              Update Policy
            </Button>
          ) : (
            <React.Fragment>
              <Button
                type="submit"
                onClick={updatePolicy}
                style={{ margin: '0px 10px', borderRadius: '4px' }}
                isDisabled={isSaveButtonDisabled}
              >
                Save
              </Button>
              <Button
                isDisabled={isButtonDisabled}
                style={{ margin: '0px 10px', borderRadius: '4px' }}
                onClick={handleReset}
              >
                Reset
              </Button>
              <Button
                isDisabled={isButtonDisabled}
                onClick={handleCancel}
                style={{ margin: '10px', borderRadius: '4px' }}
              >
                Cancel
              </Button>
            </React.Fragment>
          )}
          <Button
            onClick={() => {
              changeComponent('payment');
            }}
            style={{ margin: '10px', borderRadius: '4px' }}
          >
            Payment Status
          </Button>
          <Button
            onClick={() => {
              console.log('clicked', componentType);
              changeComponent('hold');
            }}
            style={{ margin: '10px', borderRadius: '4px' }}
          >
            Hold Status
          </Button>
          <Button
            onClick={() => {
              changeComponent('Broker');
            }}
            style={{ margin: '10px', borderRadius: '4px' }}
          >
            Broker
          </Button>
          <Button
            onClick={() => {
              changeComponent('note');
            }}
            isDisabled={isNoteButtonDisabled}
            style={{ margin: '10px', borderRadius: '4px' }}
          >
            Notes
          </Button>
          {notesStatus === 'available' ? (
            <img
              style={{ width: '25px' }}
              src={notes_available.src}
              alt="Notes Available"
            />
          ) : (
            <img
              style={{ width: '25px' }}
              src={notes_empty.src}
              alt="Notes Empty"
            />
          )}
        </Grid.Col>
        <Grid.Col span={12}>
          {componentType === 'payment' ? (
            <React.Fragment>
              <CustomerPayment
                data={formData}
                breadcrums={breadcrumsInfo}
                onClose={() => {
                  return setComponentType('');
                }}
              />
            </React.Fragment>
          ) : (
            ''
          )}

          {componentType === 'Broker' ? (
            <React.Fragment>
              <CustomerBroker
                data={formData}
                onClose={() => {
                  return setComponentType('');
                }}
              />
            </React.Fragment>
          ) : (
            ''
          )}

          {componentType === 'note' ? (
            <React.Fragment>
              <CustomerContractNote
                data={formData}
                notesData={notes}
                onClose={() => {
                  return setComponentType('');
                }}
                handleNoteSave={() => {
                  setRefreshNote(true);
                }}
              />
            </React.Fragment>
          ) : (
            ''
          )}
          {componentType === 'history' ? (
            <React.Fragment>
              <CustomerHistory
                data={formData}
                onClose={() => {
                  return setComponentType('');
                }}
              />
            </React.Fragment>
          ) : (
            ''
          )}
          {componentType === 'hold' ? (
            <React.Fragment>
              <CustomerHoldStatus
                data={formData}
                onClose={() => {
                  return setComponentType('');
                }}
              />
            </React.Fragment>
          ) : (
            ''
          )}
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
