/* eslint-disable react/react-in-jsx-scope */
import { Grid } from '@abyss/web/ui/Grid';
import React, { useEffect, useState } from 'react';
import { Button } from '@abyss/web/ui/Button';
import axios from 'axios';
import { MlreDataTable } from '@src/common/MlreAbyssComponent';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { BASE_URL } from '../utils/endpoints';

export const CustomerPayment = ({ data, onClose, breadcrums }) => {
  const [paymentData, setpaymentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noPaymentFound, setNoPaymentFound] = useState(false);
  const { navigate } = useRouter();
  console.log('breadcrums----on payment', breadcrums);

  const checkIfAllNull = (resonseData) => {
    return resonseData.every((item) => {
      return Object.values(item).every((val) => {
        return val === null;
      });
    });
  };

  const handleRedirect = async (rowData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}payment/search`,
        {
          pmtDocTypCd: rowData.pmtDocTypCd,
          pmtDocNbr: rowData.payDocNbr,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const selectedPaymentData = response.data?.[0];
      const breadcrumData = breadcrums || [
        { title: 'Customer Search', href: '/Customer/' },
        {
          title: 'Customer Policy',
          href: '/Customer#form',
          state: { breadcrumData: true, SearchData: data },
        },
      ];
      navigate('/payment-detail', {
        state: { rowData, selectedPaymentData, breadItem: breadcrumData },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Check#',
        accessor: 'payDocNbr',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                return handleRedirect(row.original);
              }}
            >
              {row.original.payDocNbr}
            </a>
          );
        },
      },
      {
        Header: 'Check Amount',
        accessor: 'totPayAmt',
        disableSortBy: true,
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'Check Date',
        accessor: 'payDocDt',
        disableSortBy: true,
        Cell: ({ value }) => {
          if (!value || value === null || value === 'null') {
            return '';
          }
          const date = new Date(value);
          const formattedDate = `${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}/${date
            .getDate()
            .toString()
            .padStart(2, '0')}/${date.getFullYear()}`;
          return formattedDate;
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (data) {
      const fetchData = async () => {
        try {
          const response = await axios.post(
            `${BASE_URL}customer/payment/status`,
            {
              srcSystemContrId: data[0]?.srcSystemContrId,
              rbtReportYr: data[0]?.rbtReportYr,
              srcSystemCd: data[0]?.srcSystemCd,
              atr5: data[0]?.atr5,
              // id: '1362480',
            },
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                'Content-Type': 'application/json',
              },
            }
          );

          if (Array.isArray(response.data) && response.data.length > 0) {
            if (checkIfAllNull(response.data)) {
              setpaymentData([]);
              alert('No payment status.');
            } else {
              setpaymentData(response.data);
              // setNoPaymentFound(false);
            }
          } else {
            setpaymentData([]);
            alert('No payment status.');
          }
        } catch (error) {
          setpaymentData([]);
          // setNoPaymentFound(true);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [data]);

  return (
    <Grid css={{ marginTop: '30px' }}>
      {loading ? (
        <p />
      ) : paymentData.length > 0 ? (
        <React.Fragment>
          <MlreDataTable data={paymentData} columns={columns} />
          <Grid.Col span={12} css={{ textAlign: 'center', marginTop: '10px' }}>
            <Button onClick={onClose}>Close</Button>
          </Grid.Col>
        </React.Fragment>
      ) : null}
    </Grid>
  );
};
