import React, { useEffect, useState } from 'react';
import { Table } from '@abyss/web/ui/Table';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { useRouter } from '@abyss/web/hooks/useRouter';
import axios from 'axios';
import useAuth from '@src/auth/useAuth';
import { MlreLoader } from '@src/common/MlreAbyssComponent';
import { Grid } from '@abyss/web/ui/Grid';
import { Text } from '@abyss/web/ui/Text';
import { BASE_URL } from '../utils/endpoints';

export const EmpolyerConversionCheckTable = ({ data, breadcrums }) => {
  const { getLocation, navigate } = useRouter();
  const { msid } = useAuth();
  const [reistributeList, setreistributeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [validatePaymentData, setValidatePaymentData] = useState();
  const [redistributeStatus, setRedistributeStatus] = useState(false);

  const getRedistributeList = async () => {
    const payload = {
      rbtRptYr: data.rbtRptYr,
      srcSysCd: data.srcSysCd,
      srcSysContrId: data.srcSysContrId,
    };

    try {
      const result = await axios.post(
        `${BASE_URL}payment/redistributed/list`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (result) {
        setreistributeList(result.data);
      }
    } catch (error) {
      console.log('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkredistribute = reistributeList.some((list) => {
      if (
        !(
          list?.payStsCd === 'OUTSTANDING' ||
          list?.payStsCd === 'VOID' ||
          list?.payStsCd === 'DORMANT'
        ) ||
        !(list?.atr2 == null || list?.atr2 == 'Reissue Rejected') ||
        !(list?.atr12 === null || list?.atr12 == '5')
      ) {
        return false;
      }
      return true;
    });

    setRedistributeStatus(checkredistribute);
  }, [reistributeList]);

  useEffect(() => {
    if (data) {
      getRedistributeList();
    }
  }, [data]);

  const validatePayment = async () => {
    try {
      const payload = {
        payDocNum: data?.pmtDocNbr,
        modifiedDateTime: data?.modifiedDateTime,
      };
      const result = await axios.post(`${BASE_URL}payment/validate`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });
      if (result?.data) {
        setValidatePaymentData(result?.data);
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const columns = [
    { name: 'Policy Number', key: 'srcSysContrId' },
    { name: 'Legal Entity ID', key: 'lglEntyId' },
    { name: 'MLRE Case Size', key: 'mlrCseSz' },
    { name: 'Situs State', key: 'mlrSitusStCd' },
    { name: 'Check Number', key: 'pmtDocNbr' },
    { name: 'Check status', key: 'payStsCd' },
  ];

  const handleSubmit = async () => {
    if (!redistributeStatus) {
      alert('Void request failed for check redistribution.');
    } else {
      const payload = {
        rbtRptYr: data.rbtRptYr,
        srcSysCd: data.srcSysCd,
        srcSysContrId: data.srcSysContrId,
        atr12: '1',
        atr11: data.pmtDocNbr,
      };
      const result = await axios.post(
        `${BASE_URL}payment/update/redistributed`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      alert('Void request submitted successfully for check redistribution');
      breadcrums.pop();
      navigate('/payment-detail', {
        state: { selectedPaymentData: data, breadcrums, refreshData: true },
      });
    }
  };

  const handleValidatedSubmit = async () => {
    const validationResult = await validatePayment();
    if (validationResult === 'validation passed') {
      handleSubmit();
    } else {
      alert('Failed: Current data has been modified by other user');
    }
  };

  return (
    <React.Fragment>
      {redistributeStatus ? (
        <Layout.Stack
          css={{
            alignContent: 'center',
          }}
        >
          <Text size="md" color="#000000">
            All the checks for this policy have passed validations.
          </Text>
          <Text size="md" color="#000000">
            Following checks are issued for this policy.
          </Text>
          <Text size="md" color="#000000">
            Please click submit to void all the checks.
          </Text>
        </Layout.Stack>
      ) : (
        <Layout.Stack
          css={{
            alignContent: 'center',
          }}
        >
          <Text size="md" color="#000000">
            All the checks for this policy have not passed validations.
          </Text>
          <Text size="md" color="#000000">
            Following checks are issued for this policy.
          </Text>
          <Text size="md" color="#000000">
            Redistribution of checks cannot be proceeded further.
          </Text>
        </Layout.Stack>
      )}

      <br />
      {isLoading ? (
        <Grid>
          <MlreLoader />
        </Grid>
      ) : (
        <Grid>
          <Table
            title="Table Scroll"
            columns={columns}
            rows={reistributeList}
          />
        </Grid>
      )}
      <Layout.Group alignLayout="center">
        <Button
          onClick={handleValidatedSubmit}
          css={{
            borderRadius: '4px',
            marginTop: '400px',
          }}
        >
          Submit
        </Button>
        <Button
          onClick={() => {
            breadcrums.pop();
            navigate('/payment-detail', {
              state: { selectedPaymentData: data, breadcrums },
            });
          }}
          css={{
            borderRadius: '4px',
            marginLeft: '25px',
            marginTop: '400px',
          }}
        >
          Dismiss
        </Button>
      </Layout.Group>
    </React.Fragment>
  );
};
