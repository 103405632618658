import React, { useEffect, useState } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';

import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';

import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '@src/common/components';
import axios from 'axios';
import { Text } from '@abyss/web/ui/Text';
import useAuth from '@src/auth/useAuth';
import { MlreDataTable, MlreLoader } from '../../common/MlreAbyssComponent';
import { BASE_URL } from '../utils/endpoints';
import { CHECK_REISSUE_STATUS, REISSUE_APPROVED } from './CheckReissueContant';
import { PendingReissueApprovalIcon } from './CheckReissueHelpIcon';
import env from '../../../env.json';

const CheckReissueResult = () => {
  const [breadItem, setBreadItem] = useState([
    { title: 'Check Reissue Approval Search', href: '/check-reissue-approval' },
    {
      title: 'Check Reissue Results',
      href: '/reissue-rejected',
    },
  ]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableCount, setTableCount] = useState(100);
  const [reloadData, setReloadData] = useState(false);
  const { userRolesGroups } = useAuth();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const hasUpdateAccess = env.Check_Reissue_Status_Reissue_Approved.some(
      (role) => {
        return userRolesGroups.includes(role);
      }
    );
    console.log('hasUpdateAccess--------', hasUpdateAccess);

    setIsButtonDisabled(!hasUpdateAccess);
  }, [userRolesGroups, env]);
  console.log('isButtonDisabled-----', isButtonDisabled);

  const fetchData = async (page, pageSize) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}checkReissue/search-check-with-params`,
        {
          atr2: 'Reissue Approved',
          pageNumber: page,
          pageSize,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return {
        results: data,
        count: tableCount,
      };
    } catch (error) {
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
      return {
        results: [],
        count: 0,
      };
    }
  };

  const fetchCount = async () => {
    const { data, error } = await axios.post(
      `${BASE_URL}checkReissue/count`,
      {
        atr2: 'Reissue Approved',
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (!error && data.length > 0) {
      setTableCount(data[0].count);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchCount();
  }, []);

  const config = {
    pageSizeDefault: 50,
    uniqueStorageId: `server-approve`,
    apiPaginationCall: fetchData,
    manualSortBy: false,
    showColumnSort: false,
    apiQueryOptions: {
      requestPolicy: 'no-cache',
    },
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Check Number',
        accessor: 'payDocNbr',
        disableSortBy: true,
        align: 'center',
      },
      {
        Header: 'Policy Type',
        accessor: 'atr5',
        disableSortBy: true,
        align: 'center',
      },
      {
        Header: 'Payee Name',
        accessor: 'name',
        disableSortBy: true,
        align: 'center',
        Cell: ({ value }) => {
          return <div style={{ textAlign: 'center' }}>{value}</div>;
        },
      },
      {
        Header: 'Address',
        accessor: 'address',
        disableSortBy: true,
        align: 'center',
        Cell: ({ value }) => {
          return <div style={{ textAlign: 'center' }}>{value}</div>;
        },
        width: 300,
      },
      {
        Header: 'Check Status',
        accessor: 'payStsCd',
        disableSortBy: true,
        align: 'center',
      },

      {
        Header: 'Post Mark Date',
        accessor: 'payPstMrkDt',
        disableSortBy: true,
        align: 'center',
        Cell: ({ value }) => {
          if (value === '' || value === null || value === undefined) {
            return '';
          }
          const payData = value ? value.substring(0, value.indexOf('T')) : '';
          const modifiedDate = payData.replaceAll('-', '/');
          const splitDate = modifiedDate.split('/');
          const modifiedSplitDate = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
          return modifiedSplitDate;
        },
      },
      // {
      //   Header: 'Check Type',
      //   accessor: 'col7',
      //   disableSortBy: true,
      // },
      {
        Header: 'Reissue Status',
        accessor: 'col6',
        disableSortBy: true,
        width: 250,

        Cell: () => {
          const [value, setValue] = useState('');
          const [isChecked, setIsChecked] = useState(true);
          const handleChecked = (e) => {
            setIsChecked(true);
          };
          return (
            <RadioGroup
              label="Radio Sandbox"
              display="row"
              hideLabel
              onChange={(e) => {
                return handleChecked(e);
              }}
              value={value}
              isDisabled={isButtonDisabled}
            >
              <RadioGroup.Radio
                label={<span style={{ color: '#000000' }}>Approval</span>}
                value="Approval"
                checked={isChecked}
              />
              <RadioGroup.Radio
                label={<span style={{ color: '#000000' }}>Reject</span>}
                value="Reject"
              />
            </RadioGroup>
          );
        },
      },
    ];
  }, []);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <SubHeader
                breadItem={breadItem}
                infoTitle="Check Reissue Results"
                InfoContent={PendingReissueApprovalIcon}
              />
              <Grid>
                <Grid.Col span={12} css={{ paddingTop: '24px' }}>
                  <Grid.Col span={12}>
                    <Text css={{ fontSize: '16px', fontWeight: 'bold' }}>
                      {CHECK_REISSUE_STATUS}:
                      <Text
                        css={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          paddingLeft: '8px',
                        }}
                      >
                        {REISSUE_APPROVED}
                      </Text>
                    </Text>
                  </Grid.Col>
                  {isLoading ? (
                    <MlreLoader />
                  ) : (
                    <MlreDataTable
                      // data={tableData}
                      reloadData={reloadData}
                      columns={columns}
                      config={config}
                    />
                  )}
                </Grid.Col>
              </Grid>
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default withAuth(CheckReissueResult);
