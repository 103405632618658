import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { loginRequest } from './authConfig';

const useAuth = () => {
  const { instance, accounts } = useMsal();
  const [roles, setRoles] = useState<string[] | undefined>([]);
  const [accessToken, setAccessToken] = useState<string | undefined>('');
  const [idToken, setIdToken] = useState<string | undefined>('');
  const [readOnly, setReadOnly] = useState(false);
  const [msid, setMsid] = useState('');
  const [userName, setUserName] = useState('');
  const [userRolesGroups, setUserRolesGroups] = useState([]);

  const getRemainingTime = (token) => {
    if (!token) return 0;

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      return decodedToken.exp - currentTime;
    } catch (error) {
      console.error('Invalid token specified:', error);
      return 0;
    }
  };

  const handleTokenExpiry = async () => {
    if (accounts && accounts.length > 0) {
      const account = accounts[0];

      const accessTokenRemainingTime = getRemainingTime(accessToken);

      const idTokenRemainingTime = getRemainingTime(idToken);

      // Refresh ID token if less than 5 minutes remaining
      if (idToken && idTokenRemainingTime < 2 * 60) {
        try {
          const response = await instance.acquireTokenSilent({
            scopes: ['User.Read'],
            account,
          });

          setIdToken(response.account.idToken);
        } catch (error) {
          console.error('ID token refresh failed', error);
          instance.logoutRedirect();
        }
      }
      // Log out if access token is expired
      // if (accessToken && accessTokenRemainingTime <= 0) {
      //   console.log("logout funciton --------")
      //   instance.logoutRedirect();
      // }
    }
  };

  const getToken = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      setAccessToken(response?.accessToken);
      setIdToken(response?.idToken);
    } catch (error) {
      console.error('Token acquisition failed', error);
    }
  };

  useEffect(() => {
    if (idToken !== null && idToken !== undefined && idToken !== '') {
      localStorage.setItem('jwttoken', idToken);
    }
  }, [idToken]);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const account = accounts[0];
      setRoles(account.idTokenClaims?.roles);
      setUserRolesGroups(account.idTokenClaims?.groups);
      setMsid(account.idTokenClaims.MSID);
      setIdToken(accounts[0]?.idToken);

      if (account.idTokenClaims?.roles?.includes('Read')) {
        setReadOnly(true);
      }
      if (account.name) {
        const fullName = account.name;
        const formattedName = fullName.includes(',')
          ? fullName.split(', ').reverse().join(' ')
          : fullName;
        setUserName(formattedName);
      }
    }

    // Initial token acquisition
    getToken();

    // Set interval to check token expiry every 30 minutes
    const interval = setInterval(handleTokenExpiry, 30 * 60 * 1000);

    return () => {
      return clearInterval(interval);
    };
  }, [instance, accounts]);

  return {
    roles,
    accessToken,
    readOnly,
    msid,
    userName,
    userRolesGroups,
    idToken,
  };
};

export default useAuth;
