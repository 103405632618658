import React, { useEffect, useState } from 'react';
import { Link } from '@abyss/web/ui/Link';
// import { Link, useNavigate } from 'react-router-dom';
// import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { SubHeader } from '@src/common/components';
import { HelpContent } from '@src/components/Subscriber/utils';
import { isEmpty } from 'lodash';
import { CustomerPolicyForm } from './CustomerPolicyForm';
import { CustomerDataLabel } from './CustomerDataLabel';
import { CustomerFormViewDetails } from './CustomerFormViewDetails';
import { CustomerFormData } from './CustomerConstants';

export const CustomerPolicy = ({ SearchData, breadItem }) => {
  console.log('SearchData---------', SearchData);
  const [showHistory, setShowHistory] = useState(false);
  const { navigate } = useRouter();
  const { getLocation } = useRouter();
  const { state } = getLocation();

  const breadItemInfo = breadItem || [
    { title: 'Customer Search', href: '/Customer' },
    {
      title: 'Customer Policy',
      href: '/Customer#form',
      state: {
        breadcrumData: true,
        SearchData,
      },
    },
  ];
  const hanldeCustomerPolicyList = () => {
    navigate('/customer-policyList', {
      state: {
        data: { SearchData },
        breadcrums: breadItem,
      },
    });
  };

  const handleCustomerSubscriberList = () => {
    navigate('/customer/subscriber/list', {
      state: {
        data: { SearchData },
        breadcrums: breadItemInfo,
      },
    });

    // navigate('/customer/subscriber/list', {
    //   state: {
    //     data: { SearchData },
    //     breadcrumsInfo: breadItem,
    //   },
    // });
  };

  const searchLabel = [
    {
      fieldName: CustomerFormData.sourceSystem,
      value: SearchData[0]?.srcSystemCd,
    },
    {
      fieldName: CustomerFormData.rebateYear,
      value: SearchData[0]?.rbtReportYr,
    },
    { fieldName: CustomerFormData.platCust, value: SearchData[0]?.platCustNbr },
    {
      fieldName: CustomerFormData.customerId,
      value: (
        <Link
          onClick={(e) => {
            e.preventDefault();
            hanldeCustomerPolicyList();
          }}
          href="#"
        >
          {SearchData[0]?.srcSysCustId}
        </Link>
      ),
    },
    {
      fieldName: CustomerFormData.policy,
      value: (
        <Link
          onClick={(e) => {
            e.preventDefault();
            handleCustomerSubscriberList();
          }}
          href="#"
          isDisabled={SearchData[0]?.atr5 === 'STT'}
          isActive={SearchData[0]?.atr5 !== 'STT'}
        >
          {SearchData[0]?.srcSystemContrId}
        </Link>
      ),
    },
    {
      fieldName: CustomerFormData.cancelDate,
      value: SearchData[0]?.cancelDate,
    },
    {
      fieldName: CustomerFormData.franchiseCode,
      value: SearchData[0]?.franchCd,
    },
    { fieldName: CustomerFormData.policyType, value: SearchData[0]?.atr5 },
  ];

  return (
    <React.Fragment>
      <CustomerDataLabel searchLabel={searchLabel} SearchData={SearchData} />
      <CustomerPolicyForm formData={SearchData} breadcrumsInfo={breadItem} />

      {/* <CustomerFormViewDetails
        showHistory={showHistory}
        onCallBack={() => {}}
        data={SearchData}
      /> */}
    </React.Fragment>
  );
};
