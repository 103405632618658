import { useForm } from '@abyss/web/hooks/useForm';
import { Text } from '@abyss/web/ui/Text';
import { Card } from '@abyss/web/ui/Card';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Flex } from '@abyss/web/ui/Flex';
import { Button } from '@abyss/web/ui/Button';
import { RichTextEditor } from '@abyss/web/ui/RichTextEditor';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../../styles/styles.module.css';
import { BASE_URL } from '../utils/endpoints';

export const CustomerContractNote = ({
  data,
  notesData,
  onClose,
  handleNoteSave,
}) => {
  const [noteDetails, setNoteDetails] = useState([]);
  const [notes, setNotes] = useState('');
  const [editorKey, setEditorKey] = useState(0); // Add a key state
  const [isSaveButtonDisabled, setSaveIsButtonDisabled] = useState(false);

  const form = useForm({
    defaultValues: {
      noteDesc: '',
    },
  });

  useEffect(() => {
    const noteType = 'Customer_Contract';
    const fetchNotes = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}customer/get/notes/list/${data[0]?.rbtReportYr}/${data[0]?.custContrId}/${noteType}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.data) {
          setNoteDetails(response.data);
        } else {
          console.log('data not found');
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchNotes();
  }, [data]);
  const handleReset = () => {
    form.reset({
      usage: '', // Reset to default value
    });
    setNotes(''); // Reset notes state
    setEditorKey((prevKey) => {
      return prevKey + 1;
    }); // Change the key to force re-render
    console.log('handle reset clicked');
  };

  const handleSaveNote = async () => {
    setSaveIsButtonDisabled(true);
    console.log('Notes:', notes); // Log the current value of notes
    if (!notes || notes.trim() === '') {
      alert('Please add a note before saving.');
      setSaveIsButtonDisabled(false);
      return;
    }

    const payload = {
      custContrId: data[0]?.custContrId,
      rbtYear: data[0]?.rbtReportYr,
      noteDesc: encodeURIComponent(notes).replaceAll('%', 'perct'),
      noteType: 'Customer_Contract',
      modDttm: data[0]?.modDttm,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}customer/notes/save`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data) {
        alert(response.data);
        form.reset();
        setNotes(''); // Reset notes state
        handleNoteSave();
        handleReset();
        // onClose();
        setSaveIsButtonDisabled(false);
      } else {
        alert('message');
      }
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
      setSaveIsButtonDisabled(false);
    }
  };

  return (
    <Grid>
      <Grid.Col span={{ xs: 12, md: 6 }}>
        <Grid.Col span={12} css={{ textAlign: 'center' }}>
          <Text color="$interactive1" fontWeight="bold">
            Enter Note
          </Text>
        </Grid.Col>
        <FormProvider state={form}>
          <RichTextEditor
            label=""
            key={editorKey}
            format="html"
            hideLabel
            value={notes}
            onChange={(e) => {
              return setNotes(e);
            }}
          >
            <RichTextEditor.Toolbar>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Undo />
                <RichTextEditor.Redo />
              </RichTextEditor.ControlsGroup>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
              </RichTextEditor.ControlsGroup>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Lists />
                <RichTextEditor.Headings />
              </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>
          </RichTextEditor>
          <Flex direction="row" justify="center" className={styles.Layout}>
            <Button
              type="submit"
              onClick={handleSaveNote}
              className={styles.Button}
              isDisabled={isSaveButtonDisabled}
            >
              Save
            </Button>

            <Button onClick={handleReset} className={styles.Button}>
              Clear
            </Button>
            <Button onClick={onClose} className={styles.Button}>
              Close
            </Button>
          </Flex>
        </FormProvider>
      </Grid.Col>
      <Grid.Col span={{ xs: 12, md: 6 }}>
        <Grid.Col span={12} css={{ textAlign: 'center' }}>
          <Text color="$interactive1" fontWeight="bold">
            Notes
          </Text>
        </Grid.Col>
        <Card
          css={{
            textAlign: 'left',
            padding: '5px 15px',
            height: '275px',
            overflowY: 'scroll',
            ul: {
              listStyleType: 'initial',
              paddingLeft: '10px',
            },
            ol: {
              listStyleType: 'decimal',
              paddingLeft: '10px',
            },
          }}
        >
          {notesData.length > 0 ? (
            notesData.map((note) => {
              return (
                <div
                  key={note.noteId}
                  dangerouslySetInnerHTML={{
                    __html: note.noteDesc,
                  }}
                />
              );
            })
          ) : (
            <p />
          )}
        </Card>
      </Grid.Col>
    </Grid>
  );
};
