import { TextInput } from '@abyss/web/ui/TextInput';
import React, { useState, useEffect } from 'react';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Grid } from '@abyss/web/ui/Grid';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import axios from 'axios';
import { ConfigurationForm, ConfigurationFormData } from './Constants';
import styles from '../../styles/styles.module.css';
import { BASE_URL } from '../utils/endpoints';

export const ConfigurationSearchForm = ({ data, value, callbackSubmit }) => {
  const [tableData, setTableData] = useState({});
  const [originalValues, setOriginalValues] = useState({});
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [isSaveButtonDisabled, setSaveIsButtonDisabled] = useState(false);
  const [resetData, setResetData] = useState(false);
  const form = useForm();

  const date = new Date();
  const isoString = date.toISOString();
  const formattedDate = `${isoString.slice(5, 7)}/${isoString.slice(
    8,
    10
  )}/${isoString.slice(0, 4)}`;
  console.log('orignial values', originalValues);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await data;
      setTableData(newData);

      const formValues = form.getValues();

      const initialValues = {
        paraValue: newData.PARM_VAL || '',
        startDate: newData.startDate || '',
        attribute1: newData?.ATR1 || '',
        attribute2: newData?.ATR2 || '',
        attribute3: newData?.ATR3 || '',
        attribute4: newData?.ATR4 || '',
        attribute5: newData?.ATR5 || '',
        attribute6: newData?.ATR6 || '',
        attribute7: newData?.ATR7 || '',
        attribute8: newData?.ATR8 || '',
        attribute9: newData?.ATR9 || '',
        attribute10: newData?.ATR10 || '',
        attribute11: newData?.ATR11 || '',
        attribute12: newData?.ATR12 || '',
        attribute13: newData?.ATR13 || '',
        attribute14: newData?.ATR14 || '',
        attribute15: newData?.ATR15 || '',
        attribute16: newData?.ATR16 || '',
        attribute17: newData?.ATR17 || '',
        attribute18: newData?.ATR18 || '',
        attribute19: newData?.ATR19 || '',
        attribute20: newData?.ATR20 || '',
      };

      setOriginalValues(initialValues);

      form.setValue('paraValue', initialValues?.paraValue);
      form.setValue('startDate', initialValues?.startDate);
      form.setValue('attribute1', initialValues?.attribute1);
      form.setValue('attribute2', initialValues?.attribute2);
      form.setValue('attribute3', initialValues?.attribute3);
      form.setValue('attribute4', initialValues?.attribute4);
      form.setValue('attribute5', initialValues?.attribute5);
      form.setValue('attribute6', initialValues?.attribute6);
      form.setValue('attribute7', initialValues?.attribute7);
      form.setValue('attribute8', initialValues?.attribute8);
      form.setValue('attribute9', initialValues?.attribute9);
      form.setValue('attribute10', initialValues?.attribute10);
      form.setValue('attribute11', initialValues?.attribute11);
      form.setValue('attribute12', initialValues?.attribute12);
      form.setValue('attribute13', initialValues?.attribute13);
      form.setValue('attribute14', initialValues?.attribute14);
      form.setValue('attribute15', initialValues?.attribute15);
      form.setValue('attribute16', initialValues?.attribute16);
      form.setValue('attribute17', initialValues?.attribute17);
      form.setValue('attribute18', initialValues?.attribute18);
      form.setValue('attribute19', initialValues?.attribute19);
      form.setValue('attribute20', initialValues?.attribute20);
    };
    fetchData();
  }, [data, resetData]);

  useEffect(() => {
    if (data?.accCode === 'R') {
      setIsUpdateDisabled(true);
    } else {
      setIsUpdateDisabled(false);
    }
  }, [data]);

  const onSubmit = () => {};

  const updateDetails = async () => {
    const formData = form.getValues();
    setSaveIsButtonDisabled(true);
    console.log('update formData', formData);

    const hasChanges = Object.keys(originalValues).some((key) => {
      return formData[key] !== originalValues[key];
    });

    if (!hasChanges) {
      alert('No changes to save.');
      setSaveIsButtonDisabled(false);
      return;
    }

    const payload = {
      rebateReportYear: value?.rebateReportYear,
      parameterType: value?.parameterType,
      parameterCategory: value?.parameterCategory,
      mlreCnfgParamCd: data?.mlreCnfgParamCd,
      parameterName: tableData?.parameterCategory_Name,
      parameterValue: formData.paraValue || '',
      parameterValueHighNumber: data?.parameterValueHighNumber || '',
      parameterValueLowNumber: data?.parameterValueLowNumber || '',
      startDate: formData.startDate || '',
      endDate: formattedDate,
      modifiedById: data?.modifiedById || '',
      attribute1: formData?.attribute1 || '',
      attribute2: formData?.attribute2 || '',
      attribute3: formData?.attribute3 || '',
      attribute4: formData?.attribute4 || '',
      attribute5: formData?.attribute5 || '',
      attribute6: formData?.attribute6 || '',
      attribute7: formData?.attribute7 || '',
      attribute8: formData?.attribute8 || '',
      attribute9: formData?.attribute9 || '',
      attribute10: formData?.attribute10 || '',
      attribute11: formData?.attribute11 || '',
      attribute12: formData?.attribute12 || '',
      attribute13: formData?.attribute13 || '',
      attribute14: formData?.attribute14 || '',
      attribute15: formData?.attribute15 || '',
      attribute16: formData?.attribute16 || '',
      attribute17: formData?.attribute17 || '',
      attribute18: formData?.attribute18 || '',
      attribute19: formData?.attribute19 || '',
      attribute20: formData?.attribute20 || '',
      modifiedDate: tableData?.modifiedDate
    };

    try {
      const response = await axios.post(
        `${BASE_URL}configuration/update-config-with-params`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data) {
        callbackSubmit();
        alert(response.data);
        setSaveIsButtonDisabled(false);
      } else {
        console.log('not updated');
        setSaveIsButtonDisabled(false);
      }
    } catch (error) {
      console.error('Error:', error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  const handleReset = () => {
    const currentValue = form.getValues();
    const originalValuesClone = { ...originalValues };
    Object.entries(originalValuesClone).reduce((acc, [key, value]) => {
      if (currentValue[key] !== value) {
        form.setValue(key, value);
      }
      return acc;
    }, {});
    setResetData(true);
  };

  return (
    <React.Fragment>
      <FormProvider state={form} onSubmit={onSubmit}>
        <Grid>
          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 4 }}>
            <Grid.Col span={12} css={{ paddingTop: '16px' }}>
              <Text css={{ display: 'flex' }}>
                <Text css={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                  {ConfigurationForm.rebateReportYear}
                </Text>
                <Text css={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                  {value.rebateReportYear}
                </Text>
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Text css={{ display: 'flex' }}>
                <Text css={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                  {ConfigurationForm.parameterType}
                </Text>
                <Text css={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                  {value.parameterType}
                </Text>
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Text css={{ display: 'flex' }}>
                <Text css={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                  {ConfigurationForm.parameterCategory}
                </Text>
                {/* <Text css={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}> */}
                <div
                  style={{
                    flex: 1,
                    fontSize: '16px',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '300px',
                  }}
                  title={tableData?.parameterCategory}
                >
                  {tableData?.parameterCategory}
                </div>

                {/* </Text> */}
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Text css={{ display: 'flex' }}>
                <Text css={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                  {ConfigurationForm.parameterName}
                </Text>
                <div
                  style={{
                    flex: 1,
                    fontSize: '16px',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '300px',
                  }}
                  title={tableData?.parameterCategory_Name}
                >
                  {tableData?.parameterCategory_Name}
                </div>
                {/* <Text css={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                  {tableData.parameterCategory_Name}
                </Text> */}
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.parameterValue}
                model="paraValue"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              {/* <TextInput
                label={ConfigurationForm.attribute}
                // value={tableData?.STRT_DT}
                model="startDate"
              /> */}
              <DateInput
                label={ConfigurationForm.attribute}
                model="startDate"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute1}
                model="attribute1"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute2}
                model="attribute2"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>

            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute3}
                model="attribute3"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute4}
                model="attribute4"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={{ xs: 8, md: 3, lg: 4, xl: 4 }}
            css={{ paddingLeft: '24px' }}
          >
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute5}
                model="attribute5"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute6}
                model="attribute6"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute7}
                model="attribute7"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute8}
                model="attribute8"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute9}
                model="attribute9"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute10}
                model="attribute10"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute11}
                model="attribute11"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>

            <Grid.Col span={24}>
              <TextInput
                label={ConfigurationForm.attribute12}
                model="attribute12"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col span={{ xs: 8, md: 3, lg: 4, xl: 4 }}>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute13}
                model="attribute13"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute14}
                model="attribute14"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute15}
                model="attribute15"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute16}
                model="attribute16"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute17}
                model="attribute17"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute18}
                model="attribute18"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute19}
                model="attribute19"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>

            <Grid.Col span={12}>
              <TextInput
                label={ConfigurationForm.attribute20}
                model="attribute20"
                isDisabled={isUpdateDisabled}
              />
            </Grid.Col>
          </Grid.Col>
        </Grid>
      </FormProvider>
      {data?.accCode === 'W' ? (
        <Flex direction="row" justify="center" className={styles.Layout}>
          <Button
            // type="submit"
            onClick={updateDetails}
            className={styles.Button}
            isDisabled={isSaveButtonDisabled}
          >
            {ConfigurationFormData.save}
          </Button>
          <Button
            // type="reset"
            onClick={handleReset}
            className={styles.Button}
          >
            {ConfigurationFormData.reset}
          </Button>
        </Flex>
      ) : (
        <div />
      )}
    </React.Fragment>
  );
};
