import React, { useState, useEffect } from 'react';
import { CustomerForm } from '@src/components/customer/CustomerForm';
import { CustomerPolicy } from '@src/components/customer/CustomerPolicy';
import {
  FormContainer,
  FormOuterContainer,
  mediumSize,
} from '@src/common/utils/StyleConstant';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import { Grid } from '@abyss/web/ui/Grid';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '@src/common/components';
import { useRouter } from '@abyss/web/hooks/useRouter';
import {
  CustomerSearchIcon,
  CustomerPolicyIcon,
} from '@src/components/customer/CustomerHelpIcons';
import { MlreLoader } from '../../common/MlreAbyssComponent';

const Customer = () => {
  const [showCustomerForm, setShowCustomerForm] = useState(true);
  const [searchData, setSearchData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [breadItem, setBreadItem] = useState([
    { title: 'Customer Search', href: '/Customer/' },
  ]);
  const { navigate, getLocation } = useRouter();
  const { hash, state } = getLocation();

  const isForm = hash === '#form';
  const breadcrumData = state?.breadcrumData;

  const handleSubmit = async (data) => {
    setDataLoading(true); // Show loader
    try {
      // Simulate an asynchronous operation (e.g., fetching data)
      await new Promise((resolve) => {
        return setTimeout(resolve, 1000);
      }); // Simulate a 1-second delay

      navigate('#form');
      setShowCustomerForm(false);
      setSearchData(data);
      setBreadItem([
        { title: 'Customer Search', href: '/Customer' },
        { title: 'Customer Policy', href: '/Customer#form' },
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setDataLoading(false); // Hide loader after data is set or error occurs
    }
  };

  const isMedium = useMediaQuery(mediumSize);

  useEffect(() => {
    if (!isForm) {
      setShowCustomerForm(true);
    } else if (breadcrumData) {
      setBreadItem([
        { title: 'Customer Search', href: '/Customer/' },
        { title: 'Customer Policy', href: '/Customer#form' },
      ]);
      setShowCustomerForm(false);
      setSearchData(state.SearchData);
    } else {
      setShowCustomerForm(false);
      // navigate('');
    }
  }, [isForm]);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            {dataLoading ? (
              <BodyPageContainer>
                <MlreLoader />
              </BodyPageContainer>
            ) : showCustomerForm ? (
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItem}
                  infoTitle="Customer Search"
                  InfoContent={CustomerSearchIcon}
                />
                <FormOuterContainer>
                  <FormContainer medium={isMedium}>
                    <CustomerForm onSearch={handleSubmit} />
                  </FormContainer>
                </FormOuterContainer>
              </BodyPageContainer>
            ) : (
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItem}
                  infoTitle="Customer Policy"
                  InfoContent={CustomerPolicyIcon}
                />
                <CustomerPolicy SearchData={searchData} breadItem={null} />
              </BodyPageContainer>
            )}

            {/* {showCustomerForm ? (
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItem}
                  infoTitle="Customer Search"
                  InfoContent={CustomerSearchIcon}
                />
                <FormOuterContainer>
                  <FormContainer medium={isMedium}>
                    <CustomerForm onSearch={handleSubmit} />
                  </FormContainer>
                </FormOuterContainer>
              </BodyPageContainer>
            ) : (
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItem}
                  infoTitle="Customer Policy"
                  InfoContent={CustomerPolicyIcon}
                />
                <CustomerPolicy SearchData={searchData} breadItem={null} />
              </BodyPageContainer>
            )} */}
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default withAuth(Customer);
