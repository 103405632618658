// authConfig.js
import {
  REACT_APP_POST_LOGOUT_REDIRECT_URI,
  REACT_APP_REDIRECT_URI,
} from 'src/components/utils/endpoints';

const clientID = 'fe878baa-7a9c-42f2-b78c-98c0d3dd9606';
const tenantID = 'db05faca-c82a-4b9d-b9c5-0f64b6755421';
const redirectUri = REACT_APP_REDIRECT_URI;

const postLogoutRedirectUri = REACT_APP_POST_LOGOUT_REDIRECT_URI;

export const msalConfig = {
  auth: {
    clientId: clientID,
    authority: `https://login.microsoftonline.com/${tenantID}`,
    redirectUri,
    postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const graphConfig = {
  graphMeEndPoint: 'https://graph.microsoft.com/v1.0/me',
};
